import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  Chip,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FaceIcon from "@mui/icons-material/Face";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import {
  cuitComitenteList,
  comitenteList,
  filtroComitenteSeleccionado,
  postComitenteAsync,
  guardarComitenteSeleccionado,
  getCuitComitenteAsync,
  postGenerarStringComitenteAsync,
} from "app/features/BusquedaComitente/BusquedaComitenteSlice";
import {
  getCuitComitente,
  postComitente,
  postGenerarStringComitente,
} from "app/features/BusquedaComitente/BusquedaComitenteAPI";
import TableComitenteBusquedaEnModificarAsociar from "components/TableComitenteBusquedaEnModificarAsociar";
import TableComitenteBusquedaEnModificarAsociado from "components/TableComitenteBusquedaEnModificarAsociado";
import { ConstructionOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import CardActions from "@mui/material/CardActions";
import { styled } from "@mui/material/styles";
import {useLocation} from 'react-router-dom';
import { ROUTES } from "constants/routes/internals";

const useStyles = makeStyles((theme) => ({
  containermt: {
    marginTop: 60,
  },
  card: {
    padding: 30,
  },

  gridmb: {
    margin: 0,
  },

  tituloModificacionComitente: {
    textAlign: "center",
    fontSize: "3vw",
  },

  espacioBotonesFinales: {
    paddingTop: 50,
  },

  chipElement: {
    display: "grid",
  },
}));

const ModificarComitente = () => {

  //desestructuro el objeto
  //const { fncCompleted, fncClose } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnText, setBtnText] = useState("modificar");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const ComitenteSelecionado = useSelector(filtroComitenteSeleccionado);
  //console.log({ comitenteSeleccionado: ComitenteSelecionado });

  const handleRegresar = () => {
    /* RECUPERAMOS PARA EL BOTON REGRESAR EL USUARIO Y EL PASSWORD MEDIANTE LOCAL STORAGE*/
    const x = localStorage.getItem("x");
    const y = localStorage.getItem("y");
    history.push('/busquedaModificarComitente?x='+ x + '&y=' + y);
  };

  //USESELECTOR LLAMAS A LOS DATOS DE OTRO LUGAR
  const ComitenteList = useSelector(comitenteList);
  const CuitComitentetList = useSelector(cuitComitenteList);



  const actuacion = ComitenteList.map((res) => res.idActuacion);
  const idActuacion = [...actuacion][0];
  //console.log(idActuacion);

  let cuitRecuperado = ComitenteList.map((res) => res.comitentes);
  console.log({ cuitRecuperado });
  let idCuitRecuperado = [...cuitRecuperado][0];
  console.log({ idCuitRecuperado });
  let pruebaCuitRecuperado = idCuitRecuperado.map((res) => res.cuit);
  console.log({ pruebaCuitRecuperado });

  //AQUI INICIALIZAMOS LOS USESTATES DE LOS BOTONES
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [btnBuscar, setBtnBuscar] = useState(false);

  const { idComitente } = useParams();
  console.log("idComitente", idComitente);
  const FiltroComitenteList = useSelector(comitenteList);
  console.log({ FiltroComitenteList });
  let firmanteCom = FiltroComitenteList.filter((comitente) => comitente.idActuacion === parseInt(idComitente))[0];
  console.log({ firmanteCom });

  const [cuit, setCuit] = useState("");
  
  const onChangeInputMask = (e) => {
    let opcion = e.target.value;
    console.log('antes', opcion);
    console.log(opcion);
    if(opcion.length === 13){
      setBtnBuscar(false);
    }
    setCuit(opcion);
    
  };

  const onValidarSoloNumeros=(e)=>{
    var key = window.event ? e.which : e.keyCode;
    if (key < 48 || key > 57) {
        //Usando la definición del DOM level 2, "return" NO funciona.
        e.preventDefault();
    }
  }

  const [tablaPrueba, setTablaPrueba] = useState([]);
  const [tablaPruebaAsociado, setTablaPruebaAsociado] = useState([]);

  const handleEventSearch = () => {
    try {
      const data = {
        cuit,
        idActuacion,
      };

      if (cuit.length === 13) {
        let cuitAgregar = 0;
        let recCuit = 0;

        for (var i = 0; i < tablaPruebaAsociado.length; i++) {
          if (cuit === pruebaCuitRecuperado[i]) {
            recCuit = 1;
          }
        }

        tablaPrueba.map((dato, index) => {
          if (dato.cuit === cuit) {
            cuitAgregar = 1;
          }
        });

        if (cuitAgregar === 0 && recCuit === 0) {
          getCuitComitente(data).then((value) => {
            setTablaPrueba(tablaPrueba.concat(value.data));
          });
        } else {
          const Alerta = Swal;
          Alerta.fire(
            "Mensaje!",
            `Ya se encuentra asociado el Nro. CUIT`,
            "warning"
          );
        }
      }
    } catch (error) {
      const Alerta = Swal;
      Alerta.fire("Mensaje!", `Error en la busqueda!`, "warning");
    }
  };

  const buttonSx = {
    ...(success && {
      bgcolor: green[0],
      "&:hover": {
        bgcolor: green[0],
      },
    }),
  };

  const handleGuardarComitentes = () => {
    const decodeCodUsu=window.localStorage.getItem("x");
    const decodeCodClave=window.localStorage.getItem("y");    
    const data = {
      idActuacion: idActuacion,
      ComitentesAASociar: tablaPrueba.concat(tablaPruebaAsociado),
      EncryptedUser: decodeCodUsu,
      EncryptedPassword: decodeCodClave,
    };
    console.log(tablaPrueba.concat(tablaPruebaAsociado));

    postGenerarStringComitente(data).then((resolve) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "DESEA GUARDAR LOS CAMBIOS?",
          //text: `${resolve.data}`,
          //text: "Se agregara la lista de comitentes al Nro. de Actuacion",
          icon: `question`,
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            //aqui va a entrar el loader
            if (!loading) {
              setSuccess(false);
              setLoading(true);
              window.setTimeout(() => {
                setSuccess(true);
                setLoading(false);
              }, 2000);
            }

            dispatch(postComitenteAsync(data)).then(() => {
              getCuitComitenteAsync(data);
              handleRegresar();
            });
            //getCuitComitenteAsync();

            //console.log(postComitenteAsync(data));
            // swalWithBootstrapButtons.fire(
            //   'Deleted!',
            //   'Your file has been deleted.',
            //   'success'
            // )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
          }
        });
    });
  };

 

  useEffect(() => {
    setBtnBuscar(true);
    console.log({cuit});
  }, []);
  


  return (
    <>
      <Container className={classes.containermt}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: "3vw", textAlign: "center" }}
                variant="h5"
              >
                AGREGAR COMITENTES A ACTUACIÓN {firmanteCom.nroActuacion}{" "}
              </Typography>
            </Grid>

            <Grid item xs={12}>
            <Typography
              className={classes.tituloModificacionComitente}
              variant="h6"
            >
              Nro.Legajo: {firmanteCom.legProf}
              
            </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ fontSize: "2vw", textAlign: "center" }}
                variant="h6"
              >
                {" "}
                Generación de Oblea:
                <Moment format="DD/MM/YYYY h:mm a">
                  {firmanteCom.fechaGeneracionOblea}
                </Moment>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={(e) => e.preventDefault()}></form>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Card className={classes.card}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/*AQUI SE ENCUENTRA EL ICONO DEL CUIT*/}
            <Grid item lg={4} md={8} xs={12}>
              <Stack direction="row" spacing={1}>
                <Chip style={{width: '100%', textAlign:'center'  }} icon={<FaceIcon />} label="CUIT COMITENTE" />
              </Stack>
            </Grid>

            {/*AQUI SE ENCUENTRA EL INPUT TEXT DEL CUIT*/}
            <Grid item lg={4} md={8} xs={12}>
              <InputMask style={{width: '100%', textAlign:'center'  }}
                            variant="outlined"
                            mask="99-99999999-9"
                            //maskPlaceholder="--_--------_--"
                            value={cuit}
                            //defaultValue={cuit}
                            onChange={onChangeInputMask}
              ></InputMask>

              {/* <input 
                style={{width: '100%', textAlign:'center'  }} 
                variant="outlined" 
                type="text" 
                value={cuit} 
                //pattern="^[0-9]+$" 
                maxLength="13"
                onKeyPress={onValidarSoloNumeros}
                onChange={onChangeInputMask} 
                placeholder="--_--------_--" 
              /> */}
            </Grid>

            {/*AQUI SE ENCUENTRA EL BOTON DEL CUIT*/}
            <Grid item lg={4} md={8} xs={12}>
              <Button
                            variant="outlined"
                            disabled={btnBuscar}
                            onClick={handleEventSearch}
                            fullWidth
                          >
                            Buscar Comitente
              </Button>
            </Grid>

            {/*AQUI SE ENCUENTRA EL CHIP ELEMENT ASOCIAR*/}
            <Grid item lg={12} md={12} xs={12}>
              <div className={classes.chipElement}>
                {/* <Chip label="Comitentes a Asociar a la actuación" variant="outlined" /> */}
                <Chip style={{backgroundColor: "#2E7D32", color: "white"}} label="COMITENTES A AGREGAR A LA ACTUACIÓN" variant="outlined" />
              </div>
            </Grid>

             {/*AQUI SE ENCUENTRA LA TABLA DE ASOCIAR*/}
             <Grid item lg={12} md={12} xs={12}>
              <TableComitenteBusquedaEnModificarAsociar tablaPrueba={tablaPrueba} setTablaPrueba={setTablaPrueba} data={cuit} />
            </Grid>

            {/*AQUI SE ENCUENTRA EL CHIP ELEMENT ASOCIADOS*/}
            <Grid item lg={12} md={12} xs={12}>
              <div className={classes.chipElement}>
                {/* <Chip style={{backgroundColor: "#2E7D32", color: "white"}} label="Comitentes ya Asociados a la actuación" variant="outlined" /> */}
                <Chip style={{backgroundColor: "#2E7D32", color: "white"}} label="COMITENTES YA AGREGADOS A LA ACTUACIÓN" variant="outlined" />
              </div>
            </Grid>

            {/*AQUI SE ENCUENTRA LA TABLA DE ASOCIADOS*/}
            <Grid item lg={12} md={12} xs={12}>
            <TableComitenteBusquedaEnModificarAsociado  tablaPruebaAsociado={tablaPruebaAsociado} setTablaPruebaAsociado={setTablaPruebaAsociado} />
            </Grid>

            {/*AQUI SE ENCUENTRA EL BOTON DE ACCION REGRESAR*/}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                        disabled={btnDisable}
                        onClick={() => handleRegresar()}
                        variant="contained"
                        color="success"
                        fullWidth
                      >
                        REGRESAR
              </Button>

            </Grid>

            {/*AQUI SE ENCUENTRA EL BOTON DE ACCION GUARDAR*/}
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ m: 0, position: 'relative' }}>
                  <Button
                    variant="contained"
                    sx={buttonSx}
                    color="success"
                    fullWidth
                    disabled={loading}
                    onClick={() => handleGuardarComitentes()}

                  >
                    GUARDAR
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[700],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
            </Grid>
          </Grid>
          </Card>
          
        </Box>
      </Container>
    </>
  );
};

export default ModificarComitente;
