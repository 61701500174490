import {
  Alert,
  AlertTitle,
  Button,
  Box,
  Card,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  Container,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  Snackbar,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FaceIcon from "@mui/icons-material/Face";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import {
  comitenteList,
  filtroComitenteSeleccionado,
} from "app/features/BusquedaComitente/BusquedaComitenteSlice";
import {
  getCuitComitente,
  postComitente,
  postGenerarStringComitente,
} from "app/features/BusquedaComitente/BusquedaComitenteAPI";
import TableComitenteBusquedaEnModificarAsociar from "components/TableComitenteBusquedaEnModificarAsociar";
import TableComitenteBusquedaEnModificarAsociado from "components/TableComitenteBusquedaEnModificarAsociado";
import { ConstructionOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import CircularProgress from '@mui/material/CircularProgress';
//import {Box} from "@mui/material/Box";
import { green } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import TableDetalleComitenteAsociado from "components/TableDetalleComitenteAsociado";

const columns = [
  { id: 'razonSocial', label: 'Razón Social', minWidth: 170 },
  { id: 'cuit', label: 'Cuit', minWidth: 170 },
];

const useStyles = makeStyles((theme) => ({
  containermt: {
    marginTop: 60,
  },
  card: {
    padding: 30,
  },

  gridmb: {
    margin: 0,
  },

  tituloModificacionComitente: {
    width: "100%",
    textAlign: "center",
  },

  espacioBotonesFinales: {
    paddingTop: 50,
  },

  chipElement: {
    display: "grid",
  },
}));

const DetalleComitente = () => {
  //desestructuro el objeto
  //const { fncCompleted, fncClose } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnText, setBtnText] = useState("modificar");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { idComitente } = useParams();
  console.log("idComitente", idComitente);
  const FiltroComitenteList = useSelector(comitenteList);
  console.log({ FiltroComitenteList });

  let firmanteCom = FiltroComitenteList.filter((comitente) => comitente.idActuacion === parseInt(idComitente))[0];
  console.log({ firmanteCom });

  const handleRegresar = () => {
     /* RECUPERAMOS PARA EL BOTON REGRESAR EL USUARIO Y EL PASSWORD MEDIANTE LOCAL STORAGE*/
     const x = localStorage.getItem("x");
     const y = localStorage.getItem("y");
    history.push('/verComitentes?x='+ x + '&y=' + y);
  };

  //INICIO PAGINACION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
   setRowsPerPage(parseInt(event.target.value, 10));
   setPage(0);
 };
 //FIN PAGINACION

  return (
    <>
      <Container className={classes.containermt}>
        <Grid container justifyContent="center">
          <Grid item lg={12} md={8}>
            <Typography
              className={classes.tituloModificacionComitente}
              variant="h4"
            >
              ACTUACIÓN NRO. {firmanteCom.nroActuacion}
            </Typography>

            <Typography
              className={classes.tituloModificacionComitente}
              variant="h6"
            >
              Nro.Legajo: {firmanteCom.legProf}
              
            </Typography>

            <Typography
              className={classes.tituloModificacionComitente}
              variant="h6"
            >
              Generación de Oblea:
              <Moment format="DD/MM/YYYY h:mm a">
                {firmanteCom.fechaGeneracionOblea}
              </Moment>
            </Typography>
            <Card className={classes.card} align="center">
              <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={4}>
                  <Grid item md={12} xs={12}>
                    <div className={classes.chipElement}>
                      <Chip
                        label="Comitentes ya Asociados a la actuación"
                        variant="outlined"
                      />
                      {/* <TableDetalleComitenteAsociado  tablaDetalleAsociado={tablaDetalleAsociado} setTablaDetalleAsociado={setTablaDetalleAsociado} /> */}

                      <TableContainer className={classes.container}>
        
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                           
              { 
                firmanteCom.comitentes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((comitentes, index) => 
                <TableRow key={index}>
                   <TableCell>{comitentes.razonSocial}</TableCell>
                   <TableCell>{comitentes.cuit}</TableCell>
                   
                </TableRow>
                )              
              }
            </TableBody>
          </Table>
          
  
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={firmanteCom.comitentes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className={classes.espacioBotonesFinales}
                ></Grid>
              </form>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    disabled={btnDisable}
                    onClick={() => handleRegresar()}
                    variant="outlined"
                    fullWidth
                  >
                    REGRESAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DetalleComitente;
