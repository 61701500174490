import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
   ROUTES
} from 'constants/routes/internals.js'

const Routes = () => {
   
   return (
      <Router basename={"/"}>
         <Switch>
            {ROUTES.LIST.map((route,index)=>(   
               <Route exact key={`Route-${index}`} path={route.PATH}>
                  <route.LAYOUT className={route.CLASS_NAME}>
                     <route.PAGE></route.PAGE>
                  </route.LAYOUT>
               </Route>)
            )}
         </Switch>
      </Router>
   )
}

export default Routes;
