import { configureStore } from '@reduxjs/toolkit';
import {combineReducers} from "redux"; 
import thunk from 'redux-thunk';
import busquedaComitenteReducer from './features/BusquedaComitente/BusquedaComitenteSlice';
import seguridadReducer from './features/Seguridad/SeguridadSlice';

const reducers = combineReducers({
  busquedaComitenteReducer,
  seguridadReducer
});


export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

