import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, Select, Container, Grid, MenuItem, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { comitenteList, getComitenteAsync, comitenteDataSearch, setDataSearch, comitenteStatus } from "app/features/BusquedaComitente/BusquedaComitenteSlice";
import { postSeguridadAsync, seguridadAuthenticated, seguridadStatus, setSeguridadSeleccionado } from 'app/features/Seguridad/SeguridadSlice';
import {getComitente} from 'app/features/BusquedaComitente/BusquedaComitenteAPI';
import { postSeguridad } from "app/features/Seguridad/SeguridadAPI";
import 'date-fns';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import TableComitentePrincipal from "components/TableComitentePrincipal";
import {useLocation} from 'react-router-dom';
import { ROUTES } from "constants/routes/internals";
import Swal from "sweetalert2";

const FiltroBusquedaComitentePrincipal = () => {

  console.log({ROUTES: ROUTES});
  //TRAEMOS LAS RUTAS PARA SER ENVIADAS AL BACKEND
  const rutaPermiso = ROUTES.VER_COMITENTE.CLASS_NAME;


  /*VAMOS A PROCEDER A PASAR DOS PARAMETROS CADA VEZ QUE ENTRAMOS A LA CONSULTA DE COMITENTES*/
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let codUsuario = query.get('x');
  let clave = query.get('y');
  //let ruta = query.get('rutaPermiso');

  let decodeCodUsu = decodeURI(codUsuario);
  let decodeClave = decodeURI(clave);

  // replace() usando una expresión Regex para reemplazar espacios en blanco
  decodeCodUsu = codUsuario.replace(/\s+/g, '+');

  // replace() usando una expresión Regex para reemplazar espacios en blanco
  decodeClave = clave.replace(/\s+/g, '+');

  let usuarioLogueado;
  let delegacionLogueda;

  usuarioLogueado= window.localStorage.getItem("Usuario_Logueado");
  delegacionLogueda = window.localStorage.getItem("Delegacion");

    const useStyles = makeStyles((theme) => ({

        tituloCabecera:{
          paddingBottom: 50
        },
      
        containermt: {
          marginTop: 60,
        },
        card: {
          padding: 30,
        },
        avatar: {
          backgroundColor: "#0f80aa",
          width: 80,
          height: 80,
        },
        icono: {
          fontSize: 50,
        },
        form: {
          marginTop: 40,
          marginBottom: 10,
        },
        gridmb: {
          margin: 0,
        },
      
        espacioCombo: {
          padding: 10,
        },
      
        gridmb2: {
          paddingTop: 10,
        },
        inpuText: {
          padding: 10,
        },
        link: {
          marginTop: 10,
          fontSize: "1.1rem",
          fontFamily: "Roboto",
          lineHeight: 1.5,
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
        appBar: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        grow: {
          flexGrow: 0, //sirve para especificar el valor de crecimiento
          [theme.breakpoints.up("md")]: {
            flexGrow: 1,
          },
        },
        linkAppBarLogo: {
          display: "inline-flex",
          alignItems: "center",
          color: "inherit",
          textDecoration: "none",
        },
        mr: {
          marginRight: 3,
        },
        buttonIcon: {
          fontSize: 14,
          padding: 0,
        },
        linkAppBarDesktop: {
          display: "inline-flex",
          alignItems: "center",
          padding: "6px 16px",
          color: "inherit",
          textDecoration: "none",
        },
        list: {
          width: 250,
        },
      
        listItem: {
          padding: 0,
        },
        linkAppBarMovil: {
          display: "inline-flex",
          alignItems: "center",
          width: "100%",
          padding: "8px 16px",
          color: "inherit",
          textDecoration: "none",
        },
      
        ListItemIcon: {
          minWidth: 35,
        },
        sectionDesktop: {
          display: "none",
          [theme.breakpoints.up("md")]: {
            display: "flex",
          },
        },
        sectionMobile: {
          display: "flex",
          flexGrow: 1,
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      
        text_title: {
          fontWeight: 600,
          color: "#494949",
          marginBottom: 10,
        },
      
        media: {
          height: 250,
          backgroundColor: "#F2F2F2",
          margin: "15px 15px 0 15px",
        },
      
        price: {
          float: "right",
          padding: "0 20px 0 20px",
          backgroundColor: "#0f8aa0",
        },
      
        text_card: {
          fontWeight: "bold",
          color: "#656565",
          marginBottom: 8,
        },
        paperImg: {
          backgroundColor: "#F2F2F2",
        },
      
        mediaDetalle: {
          width: 380,
          height: 380,
          margin: "auto",
        },
      
        text_detalle: {
          fontWeight: 500,
          color: "#494949",
          marginBottom: 5,
        },
      
        imgProductoCC: {
          backgroundColor: "#F2F2F2",
          width: 80,
          height: 70,
        },
      
        papperPading: {
          padding: 20,
        },
      
        divideTop: {
          marginBottom: 20,
        },
        gridPC: {
          margin: "auto",
          marginTop: 20,
        },
      
        buttonAnterior: {
          marginRight: 8,
        },
        formControl: {
          margin: 12,
        },
        gridLR: {
          paddingLeft: 30,
          paddingBottom: 20,
          paddingRight: 30,
        },
        divider: {
          marginTop: 12,
          marginBottom: 12,
        },
        imgProductoPC: {
          backgroundColor: "#F2F2F2",
          width: 50,
          height: 40,
        },
      
        espacioBotonesFinales: {
          paddingTop: 50,
        },
      
        espacioNro: {
          paddingTop: 62,
        },
      
        espacioMensaje: {
          padding: 10,
        },
      
        espacioCheckbox:{
          paddingTop: 25,
      },
    }));

    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();
    const ComitenteList = useSelector(comitenteList);
    const ComitenteStatus = useSelector(comitenteStatus);
    const [fechaDesde, setFechaDesde] = useState(new Date());
    const [fechaHasta, setFechaHasta] = useState(null);
    const [nroActuacion, setNroActuacion] = useState("");
    const [nroLegajo, setNroLegajo] = useState("");
    const [tablaInicialVerComitente, setTablaInicialVerComitente] = useState([]);

    //MENSAJES DE VALIDACION DE ERRORES: AQUI CREAMOS LOS USESTATE PARA MOSTRAR MENSAJES DE ERROR
    const [errorMessageFechaVigenciaDesde, setErrorMessageFechaVigenciaDesde] = useState(null);
    const [errorMessageFechaVigenciaHasta, setErrorMessageFechaVigenciaHasta] = useState(null);
    //AQUI VALIDAMOS EL PROCESO DE HABILITAR Y DESHABILITAR CAMPOS CON LOS SIGUIENTES USESTATES
    const [nroActuacionDisabled, setNroActuacionDisabled] = useState(false);
    const [nroLegajoDisabled, setNroLegajoDisabled] = useState(false);
    const [fechaGeneracionObleaDesdeDisabled, setFechaGeneracionObleaDesdeDisabled] = useState(false);
    const [fechaGeneracionObleaHastaDisabled, setFechaGeneracionObleaHastaDisabled] = useState(false);
    const [btnBuscarDisabled, setBtnBuscarDisabled] = useState(false);

    

    console.log({setSeguridadSeleccionado: setSeguridadSeleccionado});
    

      const onChangeVigenciaDesde = (e) =>{
          setFechaDesde(e);
              
      }

      const onChangeVigenciaHasta = (e) =>{
        setFechaHasta(e);
      }

      const onChangeNroActuacion =(e)=>{
        const opcion = e.target.value;
        if(opcion.length > 0){
          //setNroActuacionDisabled(true);
          setFechaGeneracionObleaDesdeDisabled(true);
          setFechaGeneracionObleaHastaDisabled(true);
          setNroLegajoDisabled(true);
          setNroLegajo("");
          setFechaHasta(null);
          setFechaDesde(null);
        }else{
          setNroActuacionDisabled(false);
          setFechaGeneracionObleaDesdeDisabled(false);
          setFechaGeneracionObleaHastaDisabled(false);
          setFechaDesde(new Date());
          setNroLegajoDisabled(false);
        }
        setNroActuacion(e.target.value);
      }

      const onChangeNroLegajo =(e)=>{
        setNroLegajo(e.target.value);
      }

        const Authenticated = useSelector(seguridadAuthenticated);
        console.log({autheticated: Authenticated});
        const status = useSelector(seguridadStatus);

      useEffect(() => {

        window.localStorage.removeItem("x");
        window.localStorage.removeItem("y");
        
        const data = {
          codUsu: decodeCodUsu,
          clave: decodeClave,
          ruta: rutaPermiso
      }

      dispatch(postSeguridadAsync(data)).then((value) => {
        //console.log({Authenticated2: Authenticated});
        console.log({payload: value});
        if(value.payload === true){
          setNroActuacionDisabled(false);
          setNroLegajoDisabled(false);
          setFechaGeneracionObleaDesdeDisabled(false);
          setFechaGeneracionObleaHastaDisabled(false);
          setBtnBuscarDisabled(false);
        }else{
          setNroActuacionDisabled(true);
          setNroLegajoDisabled(true);
          setFechaGeneracionObleaDesdeDisabled(true);
          setFechaGeneracionObleaHastaDisabled(true);
          setBtnBuscarDisabled(true);
        }
      });

     
      }, [])

      const handleEventSearch = () => {
        
        window.localStorage.setItem("x", decodeCodUsu);
        window.localStorage.setItem("y", decodeClave);

        //console.log(localStorage.getItem("codEnt"));
        const codEntRecuperado = localStorage.getItem("codEnt");
        const data = {
          nroActuacion,
          nroLegajo,
          fechaDesde,
          fechaHasta,
          decodeCodUsu,
          decodeClave,
          codEntRecuperado,
        };

        //dispatch(setDataSearch(data));
        dispatch(getComitenteAsync(data)).then((value) => {
          console.log({value});
          setTablaInicialVerComitente(value.payload);
        });
      
        console.log({status: status});
      }

    return (
        <Container className={classes.containermt}>
          <Grid container justifyContent="center">
            <Grid item lg={12} md={8}>
              <Card className={classes.card} align="center">
                <Typography className={classes.tituloCabecera} variant="h4">
                  CONSULTAR ACTUACIONES
                  <Typography>
                  <p style={{ fontSize: '10px' }}>Usuario: {usuarioLogueado}</p>
                  <p style={{ fontSize: '10px' }}>Delegación: {delegacionLogueda}</p>
                </Typography>
                </Typography>
                
                <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={1}>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                <div className={classes.datePicker}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>       
                    <DatePicker
                            DateFnsUtils
                            name="vigenciaDesde"
                            inputFormat='dd/MM/yyyy'
                            //disableFuture
                            disabled={fechaGeneracionObleaDesdeDisabled}
                            label="Fecha Generación Oblea Desde"
                            openTo="day"
                            value={fechaDesde}
                            onChange={(e) => onChangeVigenciaDesde(e)}
                            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                            />
                        <h6 className={classes.errorMesage}>{errorMessageFechaVigenciaDesde}</h6>
                </LocalizationProvider>
        </div>
                                         
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridmb}>

            <LocalizationProvider dateAdapter={AdapterDateFns}>       
                    <DatePicker
                            DateFnsUtils
                            name="vigenciaHasta"
                            inputFormat='dd/MM/yyyy'
                            //disableFuture
                            disabled={fechaGeneracionObleaHastaDisabled}
                            label="Fecha Generación Oblea Hasta"
                            openTo="day"
                            minDate={fechaDesde}
                            value={fechaHasta}
                            onChange={(e) => onChangeVigenciaHasta(e)}
                            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                            />
                        
                </LocalizationProvider>
          
              
                                       
            </Grid>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                  <div className={classes.espacioNro}>
                    <TextField
                      label="Nro. Actuación"
                      variant="standard"
                      fullWidth
                      name="nroActuacion"
                      disabled={nroActuacionDisabled}
                      value={nroActuacion}
                      onChange={(e) => onChangeNroActuacion(e)}
                    />
                  </div>

                  
            </Grid>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                  <div className={classes.espacioNro}>
                    <TextField
                      label="Nro. Legajo"
                      variant="standard"
                      fullWidth
                      name="nroLegajo"
                      type="number"
                      value={nroLegajo}
                      onChange={(e) => onChangeNroLegajo(e)}
                      disabled={nroLegajoDisabled}
                    />
                  </div>

                  
            </Grid>
            

               
            </Grid>

            <Grid container spacing={2} className={classes.espacioBotonesFinales}>
    
            <Grid item lg={12} md={12} sm={12} xs={12}>
                 
              <Button
                color="success"
                variant="contained"
                disabled={btnBuscarDisabled}
                onClick={handleEventSearch}
                fullWidth
              >
                Buscar
              </Button>
    
            </Grid>
          </Grid>
           
            </form>
              </Card>
            </Grid>
          </Grid>
    
    
          
          {/* AQUI SE ENCUENTRA EL COMPONENTE DE LA TABLA PRINCIPAL - DESARROLLADO POR - ANDRES VIERA  */}
          <TableComitentePrincipal tablaInicialVerComitente={tablaInicialVerComitente} setTablaInicialVerComitente={setTablaInicialVerComitente}/>
        </Container>
      );
};

export default FiltroBusquedaComitentePrincipal;