import React, {useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, TextField, Typography, IconButton, MenuItem, Container, Card, Button } from '@mui/material';
import {SearchOutlined} from '@mui/icons-material';



import { useSelector, useDispatch } from 'react-redux';
// import {
//    getPeliculasAsync,
//    selectPeliculas,
//    setSearch
// } from 'app/features/Peliculas/peliculasSilice';

// Styles
import 'scss/pages/home.scss';

const useStyles = makeStyles(theme => ({
   paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
   },
   img: {
      maxHeight:200
   }
}));



const Home = () => {
   const classes = useStyles();
   const dispatch = useDispatch();
   //const PeliculasList = useSelector(selectPeliculas);

   // useEffect(()=>{
   //    if(PeliculasList.length === 0)
   //       dispatch(getPeliculasAsync());
   //    return () => {
   //    }
   // },[PeliculasList]);

   useEffect(() => {
   
   }, [])
   

   // const changeInputSearch = (event)=>{
   //     dispatch(setSearch(event.target.value));
   // }

   // const keyUpInputSearch = (event)=>{
   //     if(event.keyCode===13)
   //          dispatch(getPeliculasAsync());     
   // }
   
   // const handleSearch = (event)=>{
   //       dispatch(getPeliculasAsync());     
   //  }

   return (
      // <Container className={classes.containermt}>
      // <Grid container justifyContent="center">
      //     <Grid item lg={12} md={8}>
      //         <Card className={classes.card} align="center">
      //             <Typography variant="h4">Modulo Sellos</Typography>
      //             <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
      //                 <Grid container spacing={2}>
      //                     <Grid item md={6} xs={12} className={classes.gridmb}>
      //                         <TextField
      //                         select
      //                         label="Firmante"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="firmante"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="Programacion">Programacion</MenuItem>                                            
      //                             <MenuItem value="Historia">Historia</MenuItem>
      //                             <MenuItem value="Matematica">Matematica</MenuItem>
      //                         </TextField>

      //                         <TextField
      //                         select
      //                         label="Funcion"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="funcion"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="Programacion">Programacion</MenuItem>                                            
      //                             <MenuItem value="Historia">Historia</MenuItem>
      //                             <MenuItem value="Matematica">Matematica</MenuItem>
      //                         </TextField>

      //                         <TextField
      //                         select
      //                         label="Modalidad"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="modalidad"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="Programacion">Programacion</MenuItem>                                            
      //                             <MenuItem value="Historia">Historia</MenuItem>
      //                             <MenuItem value="Matematica">Matematica</MenuItem>
      //                         </TextField>

      //                         <TextField
      //                         select
      //                         label="Delegacion"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="delegacion"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="Programacion">Programacion</MenuItem>                                            
      //                             <MenuItem value="Historia">Historia</MenuItem>
      //                             <MenuItem value="Matematica">Matematica</MenuItem>
      //                         </TextField>
      //                     </Grid>
      //                     <Grid item md={6} xs={12} className={classes.gridmb}>
      //                     <TextField
      //                         select
      //                         label="Acto Administrativo"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="actoAdministrativo"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="RMD">RMD</MenuItem>                                            
      //                         </TextField>

      //                         <TextField
      //                         select
      //                         label="Activo"
      //                         variant="outlined"
      //                         fullWidth
      //                         align="left"
      //                         name="activo"
      //                         // value={libro.categoria}
      //                         // onChange={handleChange}
      //                         >
      //                             <MenuItem value="Si">SI</MenuItem>   
      //                             <MenuItem value="No">No</MenuItem>                                           
      //                         </TextField>
      //                     </Grid>
                        
      //                     <Grid item md={12} xs={12} className={classes.gridmb}>
      //                         <Button
      //                         variant="contained"
      //                         fullWidth
      //                         color="primary"
      //                         type="submit"
      //                         >
      //                             Guardar
      //                         </Button>
      //                     </Grid>
      //                 </Grid>
      //             </form>
      //         </Card>
      //     </Grid>
      // </Grid>
  
      
      // </Container>
      <div></div>
   )
}

export default Home;
