
import React from 'react';
import DetalleComitente from 'components/DetalleComitente';

const DetalleComitentes = () => {
    return (
       <DetalleComitente />
    );
};

export default DetalleComitentes;