//Layouts
import PrincipalLayout from 'layouts/principal';
import BusquedaComitentesPrincipal from 'pages/busquedaComitente';
import BusquedaComitenteModificar from 'pages/busquedaComitenteModificar';
import DetalleComitentes from 'pages/detalleComitente';
import UsuariosNoValidos from 'pages/usuarioNoValido';
//Pages
import Home from 'pages/Home';
import ModificarComitente from 'components/ModificarComitente';
import NotFound from 'pages/NotFound';



//Classes
class ROUT {
    constructor(path, display, layout, page,classNames) {
      this.PATH = path;
      this.DISPLAY = display;
      this.LAYOUT = layout;
      this.PAGE = page;
      this.CLASS_NAME = classNames;
    }
}

//constantes internas
//mapDispatchToPropsconst HOME = new ROUT('/','HOME',PrincipalLayout, BusquedaComitentesPrincipal, 'comitentes');
const DETALLE_COMITENTE = new ROUT('/DetalleComitente/:idComitente','DETALLE_COMITENTE',PrincipalLayout, DetalleComitentes, 'detalleComitente/:idComitente');
const VER_COMITENTE = new ROUT('/VerComitentes','BUSQUEDA_COMITENTE',PrincipalLayout, BusquedaComitentesPrincipal, 'verComitentes');
const BUSQUEDA_COMITENTE_MODIFICAR = new ROUT('/BusquedaModificarComitente','BUSQUEDA_COMITENTE_MODIFICAR',PrincipalLayout, BusquedaComitenteModificar, 'busquedaModificarComitente');
const MODIFICAR_COMITENTE = new ROUT('/ModificarComitente/:idComitente','MODIFICAR_COMITENTE',PrincipalLayout, ModificarComitente, 'modificarComitente/:idComitente');
const USUARIO_NO_VALIDO = new ROUT('/usuarioNoValido','USUARIO_NO_VALIDO',PrincipalLayout, UsuariosNoValidos, 'usuarioNoValido');
const NOT_FOUND = new ROUT('*','No Disponible', PrincipalLayout, NotFound,'not-found');

//Exports
export const ROUTES = Object.freeze({
    //HOME,
    NOT_FOUND,
    //BUSQUEDA_COMITENTE,
    BUSQUEDA_COMITENTE_MODIFICAR,
    MODIFICAR_COMITENTE,
    VER_COMITENTE,
    DETALLE_COMITENTE,
    USUARIO_NO_VALIDO,
    LIST: [
        //HOME,
        //BUSQUEDA_COMITENTE,
        BUSQUEDA_COMITENTE_MODIFICAR,
        MODIFICAR_COMITENTE,
        VER_COMITENTE,
        DETALLE_COMITENTE,
        USUARIO_NO_VALIDO,
        NOT_FOUND //Dejar siempre en ultimo lugar!!!
    ]
})