import { Alert, AlertTitle, Button, Box, Card, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControl, InputLabel, Select, Container, Grid, MenuItem, Paper, Table, TableBody, TableCell,
    Snackbar, TableContainer, TableHead, TableRow, TableFooter, TablePagination, TextField, Typography } from "@mui/material";
    import Chip from '@mui/material/Chip';
    import Stack from '@mui/material/Stack';
    import { useTheme } from '@mui/material/styles';
    import React, { useEffect, useState } from "react";
    import { makeStyles } from "@mui/styles";
    import { useHistory, useParams } from "react-router";
    import { useSelector, useDispatch } from 'react-redux';
    import { comitenteList, getComitenteAsync, setComitenteSeleccionado, filtroComitenteSeleccionado, comitenteDataSearch, setDataSearch, comitenteStatus } from 'app/features/BusquedaComitente/BusquedaComitenteSlice';
    //import { funcionList, getFuncionAsync } from 'app/features/Funcion/FuncionSlice';
    //import EditarSello from "components/editarNuevoSello";
    import LinearProgress from '@mui/material/LinearProgress';
    import Moment from "react-moment";
    //import VerComitente from "components/verComitente";
    //import VerDetalleSello from "components/verDetalleSello";
  
    const columns = [
      { id: 'nroActuacion', label: 'Nro. Actuación', minWidth: 170 },
      { id: 'nroLegajo', label: 'Nro. Legajo', minWidth: 170 },
      {id: 'fechaGeneracionOblea', label: 'Fecha generación Oblea', minWidth: 170 },
      {id: 'accion', label: 'Acciones', minWidth: 170 },
    ];
      
    const useStyles = makeStyles((theme) => ({
      containermt: {
        marginTop: 30,
      },
      card: {
        padding: 30,
      },
      avatar: {
        backgroundColor: "#0f80aa",
        width: 80,
        height: 80,
      },
      icono: {
        fontSize: 50,
      },
      form: {
        marginTop: 40,
        marginBottom: 10,
      },
      gridmb: {
        margin: 0,
      },
    
      espacioCombo: {
        padding: 10,
      },
    
      gridmb2: {
        paddingTop: 10,
      },
      inpuText: {
        padding: 10,
      },
      link: {
        marginTop: 10,
        fontSize: "1.1rem",
        fontFamily: "Roboto",
        lineHeight: 1.5,
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      appBar: {
        paddingTop: 8,
        paddingBottom: 8,
      },
      grow: {
        flexGrow: 0, //sirve para especificar el valor de crecimiento
        [theme.breakpoints.up("md")]: {
          flexGrow: 1,
        },
      },
      linkAppBarLogo: {
        display: "inline-flex",
        alignItems: "center",
        color: "inherit",
        textDecoration: "none",
      },
      mr: {
        marginRight: 3,
      },
      buttonIcon: {
        fontSize: 14,
        padding: 0,
      },
      linkAppBarDesktop: {
        display: "inline-flex",
        alignItems: "center",
        padding: "6px 16px",
        color: "inherit",
        textDecoration: "none",
      },
      list: {
        width: 250,
      },
    
      listItem: {
        padding: 0,
      },
      linkAppBarMovil: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 16px",
        color: "inherit",
        textDecoration: "none",
      },
    
      ListItemIcon: {
        minWidth: 35,
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
      sectionMobile: {
        display: "flex",
        flexGrow: 1,
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
    
      text_title: {
        fontWeight: 600,
        color: "#494949",
        marginBottom: 10,
      },
    
      media: {
        height: 250,
        backgroundColor: "#F2F2F2",
        margin: "15px 15px 0 15px",
      },
    
      price: {
        float: "right",
        padding: "0 20px 0 20px",
        backgroundColor: "#0f8aa0",
      },
    
      text_card: {
        fontWeight: "bold",
        color: "#656565",
        marginBottom: 8,
      },
      paperImg: {
        backgroundColor: "#F2F2F2",
      },
    
      mediaDetalle: {
        width: 380,
        height: 380,
        margin: "auto",
      },
    
      text_detalle: {
        fontWeight: 500,
        color: "#494949",
        marginBottom: 5,
      },
    
      imgProductoCC: {
        backgroundColor: "#F2F2F2",
        width: 80,
        height: 70,
      },
    
      papperPading: {
        padding: 20,
      },
    
      divideTop: {
        marginBottom: 20,
      },
      gridPC: {
        margin: "auto",
        marginTop: 20,
      },
    
      buttonAnterior: {
        marginRight: 8,
      },
      formControl: {
        margin: 12,
      },
      gridLR: {
        paddingLeft: 30,
        paddingBottom: 20,
        paddingRight: 30,
      },
      divider: {
        marginTop: 12,
        marginBottom: 12,
      },
      imgProductoPC: {
        backgroundColor: "#F2F2F2",
        width: 50,
        height: 40,
      },
    
      espacioBotonesFinales: {
        padding: 10,
      },
    
      espacioNro: {
        paddingTop: 48,
      },
  
      root: {
        width: '100%',
        marginTop: '30px',
      },
      container: {
        maxHeight: 440,
      },
  
    }));
    
    
    const TableComitenteModificarPrincipal = ({tablaInicial, setTablaInicial}) => {
      let history = useHistory();
      const classes = useStyles();
      const dispatch = useDispatch();
      //const [funcion, setFuncion] = useState("");
      const status = useSelector(comitenteStatus);
      console.log('hola', filtroComitenteSeleccionado);
      
      const FiltroComitenteSeleccionado = useSelector(filtroComitenteSeleccionado);
      console.log({FiltroComitenteSeleccionado: FiltroComitenteSeleccionado});

      //Funcion ModificarComitente
      const handleModificarComitente = (idComitente) => {
        history.push("/ModificarComitente/" + idComitente);
      };
  
      //useSelector llamas a los datos
      const ComitenteList = useSelector(comitenteList);
      //console.log({veamosiPodemosValidarloAqui: FiltroSellosList});
      //console.log(FiltroSellosList.map((res)=>(console.log("codFuncion", res.codFuncion))));
  
      //INICIO PAGINACION
       const [page, setPage] = useState(0);
       const [rowsPerPage, setRowsPerPage] = useState(10);
     
       const handleChangePage = (event, newPage) => {
         setPage(newPage);
       };
     
       const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      //FIN PAGINACION
  
  
      //PROCESO DE MODAL PARA SELECCIONAR EL SELLO
      const [openAlert, setOpenAlert] = useState(false);
      const [modalEditar, setModalEditar] = useState(false);
      const ComitenteDataSearch = useSelector(comitenteDataSearch);
    
      const onCompleted = (completed) =>{
        console.log({completed});
        setModalEditar(completed);
        //console.log({aquiFuncion: funcion});
        dispatch(getComitenteAsync(ComitenteDataSearch));
        setOpenAlert(true);
      }
  
      
      //Esto sirve para cerrar la alerta si el sello se modifico
      const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
      };

      useEffect(() => {
        setTablaInicial(tablaInicial);
      }, [])
            
  
      // const handleModificarComitente = (comitente) =>{
      //   //dispatch(console.log(sellos));
      //   //dispatch(setComitenteSeleccionado(comitente));
      //   //setModalEditar(true);
      //   //console.log('aqui ira la vista del modificar comitente');
      // }
  
      // const handleClose = () => {
      //   setOpen(false);
      // };
      
  
      return (
        <Paper className={classes.root}>
          
        <TableContainer className={classes.container}>
        
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/*Linea de progreso mientras se ejecuta el proceso de busqueda*/}
             
              { status === 'loading' ? 
              <TableRow>
                <TableCell sx={{ padding: '0' }} colSpan={5}>
                
                  <LinearProgress color="success" />
                  
                </TableCell>
              </TableRow> : 
                tablaInicial
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((comitentes, index) => 
                <TableRow key={index}>
  
                   <TableCell>{comitentes.nroActuacion}</TableCell>
                   <TableCell>{comitentes.legProf}</TableCell>
                   <TableCell><Moment format="DD-MM-YYYY h:mm a">{comitentes.fechaGeneracionOblea}</Moment></TableCell>
                   <TableCell>
                  
                    <Stack direction="row" >
                        <Chip label="Comitentes" variant="outlined" color="success" onClick={() => handleModificarComitente(comitentes.idActuacion)}  />
                        {/* <Chip label="Modificar" variant="outlined" color="success" onClick={() => handleEditarComitente(comitentes)} /> */}
                    </Stack>
                   </TableCell>
                </TableRow>
                )              
              }
            </TableBody>
          </Table>
          
  
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={ComitenteList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  
          {/* <Dialog open={modalEditar} maxWidth="md" fullWidth align="center">
                <DialogContent>
                      <VerComitente fncClose={() => setModalEditar(false)} fncCompleted={(completed) => onCompleted(completed)} />
                </DialogContent>
            <DialogActions>
               
            </DialogActions>
            </Dialog> */}
    
  
  
         
      </Paper>
  
      );
    };
  
    // TableConsultaSellos.propTypes = {
    //   count: PropTypes.number.isRequired,
    //   onPageChange: PropTypes.func.isRequired,
    //   page: PropTypes.number.isRequired,
    //   rowsPerPage: PropTypes.number.isRequired,
    // };
    
    export default TableComitenteModificarPrincipal;
    